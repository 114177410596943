import project from 'consts:project';
import commonConfig from './common';

import appConfig from '../app/_config';
// import bookingConfig from '../booking/_config';
import contextConfig from '../context/_config';
import pageConfig from '../page/_config';
import slxConfig from '../slx/_config';


const config = async (di) => {
	commonConfig(di);

	appConfig(di);
	// bookingConfig(di);
	contextConfig(di);
	pageConfig(di);
	slxConfig(di);

	// Here you can put dynamic imports for specific project additional configs.
	// Examples:
	/*
	switch (project) {
		case 'aaa': (await import('./config-aaa')).default(di); break;
		case 'bbb': (await import('./config-bbb')).default(di); break;
	}
	*/

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');
	di
		.setValue('projectName', project)
		.setValue('baseUrl', baseUrl)
		.setValue('google/mapApiUrl', bodyData.get('googleMapApiUrl', ''))
		.setValue('google/mapApiKey', bodyData.get('googleMapApiKey', ''))
		.setParam('PageLoader', 'baseUrl', baseUrl)
		.setParam('HashScroller', 'duration', 0.5)
		.setParams('Navigation', {
			allowHistoryManagement: false,
			navigateSameUrl: true
		})
	;
};


export default config;
