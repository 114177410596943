import CollapsableHeader from '../../common/widget/collapsable-header';


const defaultValues = {
	searchActiveClass: 'searchActive',
	searchBoxAttr: 'searchBox',
	outInterval: 100
};


class HeaderWithSearch extends CollapsableHeader {

	constructor({
		root,
		element,
		defaults = {}
	}) {
		defaults = Object.assign({}, defaultValues, defaults);
		super({root: root, element: element, defaults: defaults});
		this.outTimeout = null;
		this.insideSearchBox = false;
	}


	prepare() {
		super.prepare();
		this.searchBox = this.element.querySelector(this.dataSelector(this.options.searchBoxAttr));
		if (this.searchBox) {
			this.listeners.searchFocusIn = this.events.on(this.searchBox, 'focusin', this.onFocusInSearch.bind(this));
			this.listeners.searchFocusOut = this.events.on(this.searchBox, 'focusout', this.onFocusOutSearch.bind(this));
		}
	}


	onFocusInSearch(event) {
		if (this.outTimeout) {
			clearTimeout(this.outTimeout);
			this.outTimeout = null;
		}
		this.toggleInSearchBox(true);
	}


	onFocusOutSearch(event) {
		this.outTimeout = setTimeout(() => this.toggleInSearchBox(false), this.options.outInterval);
	}


	toggleInSearchBox(isIn) {
		if (isIn !== this.insideSearchBox) {
			this.insideSearchBox = isIn;
			this.classList(this.element).toggle(this.options.searchActiveClass, isIn);
		}
	}

}


export default HeaderWithSearch;
