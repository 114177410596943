import HeaderWithSearch from './header-with-search';
import ProductsOverview from './products-overview';
import ProductDownloadWizardWrapper from './product-download-wizard-wrapper';


export default (di) => {
	di
		.setType({type: HeaderWithSearch, name: 'HeaderWithSearch', parent: 'CollapsableHeader'})
		.setType({type: ProductsOverview, name: 'ProductsOverview', parent: 'FilterableList'})
		.setType({type: ProductDownloadWizardWrapper, name: 'ProductDownloadWizardWrapper', parent: 'PageComponent'})
	;
};
