import FilterableList from '../../common/filterable-list/filterable-list';
import {intersect} from '../../common/utils/array';


const defaultValues = {
	itemTypeAttr: 'type',
	productsToggledAttr: 'productsToggled',
	productsTogglerAttr: 'productsToggler',
	familyProductsTogglerAttr: 'familyProductsToggler',
	productsAmountAttr: 'productsAmount',
	sizeSelectorAttr: 'sizeSelector',
	toggleMenuAttr: 'toggleMenu',
	openedClass: 'opened',
	largeTilesClass: 'largeTiles',
	showMenuClass: 'showMenu',
	typeFamily: 'family'
};


class ProductsOverview extends FilterableList {

	constructor({
		root,
		element,
		defaults = {}
	}) {
		defaults = Object.assign({}, defaultValues, defaults);
		super({root: root, element: element, defaults: defaults});
		this.familiesAmount = 0;
		this.familiesIndexes = [];
		this.familiesOpened = new Set();
		this.productsTogglers = [];
		this.productsToggled = false;
		this.largeTiles = false;

		this.showingMenuIndex = null;
	}


	prepare() {
		this.options = this.dataAttr().getAll();
		const opts = this.options;
		const productsTogglers = this.element.querySelectorAll(this.dataSelector(opts.productsTogglerAttr));
		for (const productsToggler of productsTogglers) {
			const value = +productsToggler.value;
			this.productsTogglers[value] = productsToggler;
		}
		this.productsToggled = (this.productsTogglers.length ? this.productsTogglers[1].checked : this.dataAttr().get(opts.productsToggledAttr));
		super.prepare();

		if (this.productsToggled) {
			this.familiesOpened = new Set(this.familiesIndexes);
		}


		const productsAmounts = this.element.querySelectorAll(this.dataSelector(opts.productsAmountAttr));
		for (const productsAmount of productsAmounts) {
			const index = this.dataAttr(productsAmount).get(opts.productsAmountAttr);
			this.items[index].productsAmountElement = productsAmount;
			this.items[index].productsAmount = +productsAmount.textContent;
		}



		this.sizeSelector = this.element.querySelector(this.dataSelector(opts.sizeSelectorAttr));

		this.listeners.productsTogglerChange = this.events.on(this.element, this.dataSelector(opts.productsTogglerAttr), 'change', this.onProductsTogglerChange.bind(this));
		this.listeners.familyProductsTogglerClick = this.events.on(this.element, this.dataSelector(opts.familyProductsTogglerAttr), 'click', this.onFamilyProductsTogglerClick.bind(this));
		this.listeners.sizeSelect = this.events.on(this.element, this.dataSelector(opts.sizeSelectorAttr), 'click', this.onSizeSelectorClick.bind(this));
		this.listeners.menuTogglerClick = this.events.on(this.element, this.dataSelector(opts.toggleMenuAttr), 'click', this.onToggleMenuClick.bind(this));
	}


	prepareItems() {
		const opts = this.options;
		const itemElements = this.element.querySelectorAll(this.dataSelector(opts.itemAttr));
		let lastFamilyIndex = 0;
		for (const itemElement of itemElements) {
			const data = this.dataAttr(itemElement);
			const index = data.get(opts.itemAttr);
			const type = data.get(opts.itemTypeAttr);
			const entry = {
				index: index,
				type: type,
				element: itemElement,
				classList: this.classList(itemElement),
				threeStateTransition: this.threeStateTransition(itemElement),
				visible: true,
				wasVisible: (type === opts.typeFamily || this.productsToggled)
			};
			if (!entry.wasVisible && !entry.classList.contains(opts.hiddenClass)) {
				entry.threeStateTransition.add(opts.hiddenClass);
			}
			this.items[index] = entry;
			if (type === opts.typeFamily) {
				this.familiesAmount++;
				this.familiesIndexes.push(index);
				entry.indexes = [];
				lastFamilyIndex = index;
			} else {
				entry.familyIndex = lastFamilyIndex;
				this.items[lastFamilyIndex].indexes.push(index);
			}
		}
		this.visibleAmount = this.items.length;
	}


	updateItemsData(visibleIndexes) {
		for (let index = 0; index < this.items.length; index++) {
			const item = this.items[index];
			// item.visible = (item.type === opts.typeFamily ?
			// 	(intersect(item.indexes, visibleIndexes).length > 0) :
			// 	(visibleIndexes.indexOf(item.index) >= 0)
			// );
			if (item.type === this.options.typeFamily) {
				item.productsAmount = intersect(item.indexes, visibleIndexes).length;
				item.visible = (item.productsAmount > 0);
			} else {
				item.visible = (visibleIndexes.indexOf(item.index) >= 0);
				if (item.visible) {
					this.visibleAmount++;
				}
			}
		}
	}


	updateDom() {
		// we close any eventually opened menu when any filter or option changes
		this.toggleItemMenu(this.showingMenuIndex);
		return super.updateDom();
	}



	updateItemDom(item, index) {
		const opts = this.options;
		let promise = Promise.resolve();
		const visible = item.visible && (item.type === opts.typeFamily || this.familiesOpened.has(item.familyIndex));
		if (item.type === opts.typeFamily) {
			item.productsAmountElement.textContent = item.productsAmount;
			item.classList.toggle(opts.openedClass, this.familiesOpened.has(index));
		}
		if (item.wasVisible !== visible) {
			promise = item.threeStateTransition[!visible ? 'add' : 'remove'](opts.hiddenClass);
			item.wasVisible = visible;
		}
		return promise;
	}


	onProductsTogglerChange(event) {
		const newValue = (this.productsTogglers[0].checked ? 0 : 1);
		if (newValue) {
			this.familiesOpened = new Set(this.familiesIndexes);
		} else {
			this.familiesOpened.clear();
		}
		this.productsToggled = newValue;
		this.updateDom();
	}


	onFamilyProductsTogglerClick(event, target) {
		const index = this.dataAttr(target).get(this.options.familyProductsTogglerAttr);
		const opened = this.familiesOpened.has(index);
		if (opened) {
			this.familiesOpened.delete(index);
		} else {
			this.familiesOpened.add(index);
		}
		const openedAmount = this.familiesOpened.size;
		if (this.productsTogglers.length) {
			if (openedAmount === this.familiesAmount && this.productsTogglers[0].checked) {
				this.productsTogglers[1].checked = true;
			} else if (openedAmount === 0 && this.productsTogglers[1].checked) {
				this.productsTogglers[0].checked = true;
			}
		}
		this.updateDom();
	}


	onSizeSelectorClick(event) {
		this.largeTiles = !this.largeTiles;
		this.classList().toggle(this.options.largeTilesClass, this.largeTiles);
	}


	onToggleMenuClick(event, target) {
		const index = this.dataAttr(target).get(this.options.toggleMenuAttr);
		const wasOpened = (this.showingMenuIndex === index);
		this.toggleItemMenu(this.showingMenuIndex);
		if (!wasOpened) {
			this.toggleItemMenu(index);
		}
	}


	toggleItemMenu(index = null) {
		if (index !== null) {
			this.items[index].classList.toggle(this.options.showMenuClass, index !== this.showingMenuIndex);
			this.showingMenuIndex = (index !== this.showingMenuIndex ? index : null);
		}
	}

}


export default ProductsOverview;
