import domMixin from '../dom/dom-mixin';


class CurrentLinkManager extends domMixin() {

	constructor({currentClass = 'currentLink'}) {
		super();
		this.currentClass = currentClass;
		this.currentLinks = [];
	}


	init() {
		this.events.on(document, 'history:pagechange', this.onPageChange.bind(this));
		this.update();
	}


	onPageChange(event) {
		this.update(event.detail.url);
	}


	update(url = null) {
		if (url === null) {
			url = location.href;
		}
		for (const link of this.currentLinks) {
			this.classList(link).remove(this.currentClass);
		}
		this.currentLinks = document.body.querySelectorAll('[href="' + url + '"]');
		for (const link of this.currentLinks) {
			this.classList(link).add(this.currentClass);
		}
	}

}

export default CurrentLinkManager;
