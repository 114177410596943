import PageComponent from '../../common/component/page-component';

const constants = {
	jsUrlsAttr: 'jsUrls',
	cssUrlsAttr: 'cssUrls'
};


class ProductDownloadWizardWrapper extends PageComponent {

	prepare() {
		this.loading = false;
		this.loadingPromise = Promise.resolve();
		if (this.dataAttr().get('autoload') === true) {
			this.load();
		}
	}


	load() {
		if (!this.loading) {
			this.loading = true;
			this.loadingPromise = new Promise((resolve) => {
				const cssUrls = this.dataAttr().get(constants.cssUrlsAttr, []);
				const jsUrls = this.dataAttr().get(constants.jsUrlsAttr, []);
				const fragment = document.createDocumentFragment();
				for (const url of cssUrls) {
					const link = document.createElement('link');
					link.setAttribute('rel', 'stylesheet');
					link.setAttribute('media', 'all');
					link.setAttribute('href', url);
					fragment.appendChild(link);
				}
				this.element.appendChild(fragment);
				// defer does not really work well appending the scripts together, so we
				// make them load one after each other to be sure they are
				// running in the right order
				let scriptPromise = Promise.resolve();
				// const scripts = [];
				for (const url of jsUrls) {
					const script = document.createElement('script');
					script.setAttribute('defer', 'defer');
					scriptPromise = scriptPromise.then(() => new Promise((solve) => {
						script.onload = () => {
							solve();
						};
						script.setAttribute('src', url);
					}));
					this.element.appendChild(script);
				}
				scriptPromise.then(() => resolve());
			});
		}
		return this.loadingPromise;
	}


	static getConstants() {
		return constants;
	}

}


export default ProductDownloadWizardWrapper;
