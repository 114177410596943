import FilterableList from './filterable-list';
import FilterableMosaicGrid from './filterable-mosaic-grid';


export default (di) => {
	di
		.setType({type: FilterableList, name: 'FilterableList', parent: 'PageComponent', setters: {
				injectHistory: di.lazyGet('navigation/history')
			}
		})
		.setType({type: FilterableMosaicGrid, name: 'FilterableMosaicGrid', parent: 'FilterableList'})
	;
};
