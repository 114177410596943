// import {getScrollTop} from 'get-scroll';
import queryString from 'query-string';
import PageComponent from '../component/page-component';
import {getScrollTop} from '../utils/get-scroll';


const defaultValues = {
	searchOnType: true,
	searchOnEmpty: true,
	useTags: false,
	waitTime: 400, // ms
	updateQueryString: true,
	searchResultsId: null,
	queryStringParamsNames: {
		prefix: '?',
		search: 's',
		page: 'p',
		tags: 't'
	},
	engines: {},
	extraParams: [
		// {name: 'category', type: 'string', queryStringName: 'c', default: ''}
	],
	autofocus: true,
	scrollUpOnResults: true,
	scrollUpOffset: 100,
	trackDelay: 1000, // ms
	inputAttribute: 'searchInput',
	actionAttribute: 'searchAction',
	busyClass: 'busy'
};


class Search extends PageComponent {

	constructor({
		element,
		root,
		defaults = {},
	}) {
		super({element: element, root: root});
		this.defaults = Object.assign({}, defaultValues, defaults);
		this.trackTimeout = null;
		this.waitTimeout = null;
		this.promise = Promise.resolve();
		this.urlChanged = false;
		this.cancelled = false;

		this.queryString = queryString;
	}


	injectSearchEngineFactory(searchEngineFactory) {
		this.searchEngineFactory = searchEngineFactory;
	}

	injectSearchEngine(searchEngine) {
		this.searchEngine = searchEngine;
	}


	injectHistory(history) {
		this.history = history;
	}


	prepare() {
		this.options = this.dataAttr().getAll();
		const opts = this.options;
		this.engines = new Map();
		for (const name in opts.engines) {
			if (opts.engines.hasOwnProperty(name)) {
				const entry = opts.engines[name];
				// console.log('engine opts', entry);
				const engineParams = entry.engineParams || {};
				const extraParams = entry.searchExtraParams || {};
				const engine = this.searchEngineFactory.newInstance(entry.engineName, engineParams);
				const resultsComponent = this.components.queryComponent(this.root, this.dataSelector('id', entry.resultsId));
				if (!resultsComponent) {
					throw Error('Search Result Component not found');
				}
				const engineEntry = {
					name: name,
					engine: engine,
					resultsComponent: resultsComponent,
					searchParamsWrapper: entry.searchParamsWrapper || null,
					extraParams: extraParams,
					trackExtraParams: entry.trackExtraParams || {},
					trackParamsWrapper: entry.trackParamsWrapper || null,
					extra: {},
					lastValue: null,
					lastTags: [],
					tags: [],
					lastPage: 1,
					running: 0
				};
				for (const extraParam of extraParams) {
					engineEntry.extra[extraParam.name] = extraParam.default;
				}
				this.listeners['results-' + name] = this.events.on(resultsComponent.getElement(), this.dataSelector(opts.actionAttribute), 'click', this.onSearchAction.bind(this));
				this.engines.set(name, engineEntry);
			}
		}

		this.listeners.searchActions = this.events.on(this.getElement(), this.dataSelector(opts.actionAttribute), 'click', this.onSearchAction.bind(this));
		this.input = this.element.querySelector(this.dataSelector(opts.inputAttribute));


		if (this.input) {
			if (opts.searchOnType) {
				this.listeners.change = this.events.on(this.input, 'input', this.onChange.bind(this));
			}
			this.input.disabled = false;
		}
	}


	start() {
		if (this.options.updateQueryString) {
			this.processUrlParams(this.getUrlParams());
		}
	}


	onChange(event) {
		if (this.waitTimeout) {
			clearTimeout(this.waitTimeout);
		}
		this.waitTimeout = setTimeout(this.submit.bind(this), this.options.waitTime);
	}


	onSearchAction(event, target) {
		event.preventDefault();
		const input = this.getInput();
		const actionData = this.dataAttr(target).get(this.options.actionAttribute);
		const engineParams = [];
		const engineName = ('engineName' in actionData ? actionData.engineName : null);
		for (const [name, engine] of this.engines) {
			if (engineName === name || engineName === null) {
				const entry = {
					name: name,
					page: 1,
					force: false
				};
				for (const key in actionData) {
					if (actionData.hasOwnProperty(key)) {
						if (key === 'page') {
							entry.page = actionData[key];
						} else if (key === 'search' && actionData.search.length) {
							input.value = actionData[key];
						} else if (key in engine.extra) {
							engine.extra[key] = actionData[key];
							entry.force = true;
						}
					}
				}
				engineParams.push(entry);
			}
		}
		this.submit(true, engineParams).then(() => {
			if ('search' in actionData) {
				const value = input.value;
				input.value = '';
				this.input.focus();
				input.value = value;
			}
		});
	}


	submit(pushUrl = true, engineParams = []) {
		if (!engineParams.length) {
			for (const name of this.engines.keys()) {
				engineParams.push({
					name: name,
					page: 1,
					force: false
				});
			}
		}
		const opts = this.options;
		const promises = [];
		if (!this.cancelled) {
			const value = this.getInput().value;
			const empty = value.length === 0;
			for (const entry of engineParams) {
				const name = entry.name;
				const engine = this.engines.get(name);
				const page = entry.page;
				const force = entry.force;
				let changed = (force || value !== engine.lastValue || page !== engine.lastPage);
				const tags = engine.tags;
				if (opts.useTags) {
					changed = (changed || engine.tags.join(',') !== engine.lastTags.join(','));
				}
				if (changed && (opts.searchOnEmpty || !empty)) {
					let params = Object.assign({}, engine.extra, {
						search: value,
						page: page,
						tags: tags
					});

					if (engine.searchParamsWrapper) {
						const p = {};
						p[engine.searchParamsWrapper] = params;
						params = p;
					}

					if (opts.trackDelay > 0 && this.trackTimeout) {
						clearTimeout(this.trackTimeout);
						this.trackTimeout = null;
					}
					engine.running++;
					engine.lastValue = value;
					engine.lastTags = tags.slice();
					this.classList(this.element).add(opts.busyClass);
					const promise = Promise.resolve()
						.then(() => (this.cancelled ? null : engine.engine.search(params)))
						.then((results) => (this.cancelled ? null : this.processResults(name, results)))
						// .then((results) => (this.cancelled ? null : this.updateUrl(results, pushUrl)))
						.then((results) => {
							engine.running--;
							return {name: name, results: results};
						}).catch((error) => {
							this.running--;
							console.log('search error');
							console.log(error);
						})
					;
					promises.push(promise);
				}
			}
		}
		if (promises.length) {
			this.promise = this.promise.then(() => Promise.all(promises))
				.then((results) => this.updateUrl(results, pushUrl))
				.then(() => {
					if (this.cancelled) {
						this.cancelled = false;
					} else {
						this.classList(this.element).remove(opts.busyClass);
						// TODO: scroll to top of the element, not of the page
						if (opts.scrollUpOnResults) {
							const rect = this.input.getBoundingClientRect();
							if (rect.top < 0) {
								const pos = rect.top + getScrollTop();
								window.scrollTo(0, pos - opts.scrollUpOffset);
							}
						}
					}
				});
		}
		return this.promise;
	}


	getInput() {
		return this.input;
	}


	reset() {
		for (const engine of this.engines.values()) {
			engine.lastValue = null;
			engine.lastTags = [];
			engine.tags = [];
			engine.lastPage = 1;
			engine.resultsComponent.reset();
		}
		this.getInput().value = '';
		this.classList(this.element).remove(this.options.busyClass);
		this.cancel();
	}


	cancel() {
		// this.promise = Promise.resolve();
		if (this.running > 0) {
			this.cancelled = true;
		}
	}


	processResults(name, results) {
		const engine = this.engines.get(name);
		return engine.resultsComponent.processResults(results).then(() => {
			// const input = this.getInput();
			if ('params' in results) {
				// if ('inputSearch' in results.params && 'sanitizedSearch' in results.params && input.value === results.params.inputSearch) {
				// 	const hasTrailSpace = input.value.length && input.value.substr(-1) === ' ';
				// 	input.value = results.params.sanitizedSearch + (hasTrailSpace ? ' ' : '');
				// }
				engine.lastPage = results.params.page;
				for (const extraParam of engine.extraParams) {
					if (extraParam.name in results.params) {
						engine.extra[extraParam.name] = results.params[extraParam.name];
					}
				}
			}
			if (this.trackTimeout) {
				clearTimeout(this.trackTimeout);
				this.trackTimeout = null;
			}
			this.trackTimeout = setTimeout(() => {
				if (results.params && results.params.inputSearch) {
					let trackParams = Object.assign({}, engine.trackExtraParams, {
						search: results.params.inputSearch
					});
					if (engine.trackParamsWrapper) {
						const p = {};
						p[engine.trackParamsWrapper] = trackParams;
						trackParams = p;
					}
					engine.engine.track(trackParams);
				}
			}, this.options.trackDelay);
			return results;
		});
	}


	updateUrl(allResults, pushUrl) {
		const opts = this.options;
		if (opts.updateQueryString) {
			const params = {};
			for (const resultsEntry of allResults) {
				const name = resultsEntry.name;
				const results = resultsEntry.results;
				const engine = this.engines.get(name);
				if ('params' in results && 'sanitizedSearch' in results.params) {
					params[opts.queryStringParamsNames.search] = results.params.sanitizedSearch;
				}
				if (engine.engine.supportPagination() && engine.lastPage !== 1) {
					params[opts.queryStringParamsNames.page] = engine.lastPage;
				}
				if (opts.useTags && engine.tags.length) {
					params[opts.queryStringParamsNames.tags] = engine.tags.join('.');
				}
				for (const extraParam of engine.extraParams) {
					const value = engine.extra[extraParam.name];
					if (value.length && extraParam.queryStringName) {
						params[extraParam.queryStringName] = value;
					}
				}
			}

			const qs = this.queryString.stringify(params);
			const url = opts.baseUrl + (qs.length ? opts.queryStringParamsNames.prefix + qs : '');
			if (pushUrl) {
				this.history.push(url, {}, document.title);
			} else {
				this.history.replace(url, {}, document.title);
			}
		}
		return allResults;
	}


	// onTagClick(event, target) {
	// 	const id = String(this.dataAttr(target).get('tagId'));
	// 	const currentIndex = this.tags.indexOf(id);
	// 	if (currentIndex >= 0) {
	// 		this.tags.splice(currentIndex, 1);
	// 	} else {
	// 		this.tags.push(id);
	// 		this.tags.sort((a, b) => a - b);
	// 	}
	// 	this.submit(true);
	// }


	// onNavigate(event) {
	// 	const requestType = event.detail.request.navigationType;
	// 	const params = this.getUrlParams();
	// 	if (requestType !== 'current' && this.eventsEnabled && params !== null) {
	// 		event.preventDefault();
	// 		this.processUrlParams(params);
	// 	}
	// }


	processUrlParams(params) {
		const input = this.getInput();
		const opts = this.options;
		if (params !== null && (opts.queryStringParamsNames.search in params && params[opts.queryStringParamsNames.search].length || opts.useTags && opts.queryStringParamsNames.tags in params)) {
			const search = opts.queryStringParamsNames.search in params ? params[opts.queryStringParamsNames.search] : '';
			const page = params[opts.queryStringParamsNames.page];
			const tags = opts.queryStringParamsNames.tags in params ? params[opts.queryStringParamsNames.tags].split('.') : [];
			// const state = this.history.getState();

			const submitParams = [];
			for (const [name, engine] of this.engines) {
				submitParams.push({
					name: name,
					page: page,
					force: false
				});
				for (const extraParam of engine.extraParams) {
					if (extraParam.queryStringName && extraParam.queryStringName in params) {
						engine.extra[extraParam.name] = params[extraParam.queryStringName];
					}
				}
			}

			input.focus();
			input.value = search;
			this.tags = tags;
			this.submit(false, submitParams)
				.then(() => input.focus())
				// .then(() => this.enableEvents())
				// .then(() => {
				// 	if ('searchScrollTop' in state) {
				// 		window.scrollTo(0, state.searchScrollTop);
				// 		this.history.mergeState({searchScrollTop: state.searchScrollTop});
				// 	}
				// })
			;
		} else if (this.autofocus) {
			input.focus();
		}
	}


	getUrlParams() {
		const params = this.queryString.parse(location.search);
		if ((this.options.queryStringParamsNames.search in params) || (this.options.useTags && (this.options.queryStringParamsNames.tags in params))) {
			return params;
		}
		return null;
	}


	// stop() {
	// 	this.disableEvents();
	// 	this.disableSearch();
	// }


	// onResultClick(event) {
	// 	console.log('result click');
	// 	if (this.eventsEnabled) {
	// 		this.disableEvents();
	// 		this.firstStart = true;
	// 		this.history.mergeState({searchScrollTop: Math.round(getScrollTop())});
	// 		// this.close();
	// 	} else {
	// 		event.preventDefault();
	// 		event.stopPropagation();
	// 	}
	// }
}


export default Search;
